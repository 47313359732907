import dayjs from 'dayjs'

export default function(app) {
    app.config.globalProperties.$filters = {
      withBtw: function(input, percentage) {
        console.log(input, percentage)
        return (input * (1+percentage/100)).toFixed( 2 )
      },
      bedrag: function(input) {
        return (input * 1).toFixed( 2 )
      },
      hyperlink: function(input) {
        let patt = /https?/i;
        let res = patt.test(input)
        if (!res) {
          return 'https://' + input
        }
        return input
      }
    }
}

