import './prototype/array'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import GlobalFunctions from './global/functions'

import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.mount('#appViewVue')
GlobalFunctions(app)
