Array.prototype.vdaaGetObjectById = function( id )
{
    var filtered = this.filter( function(obj){
        return obj.id == id;
    });

    if( filtered.length > 0 )
    {
        return filtered[ 0 ];
    }
    else
    {
        return false;
    }
}

