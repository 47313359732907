import { defineAsyncComponent } from 'vue'

export default {
  appsegmentnietvalide: defineAsyncComponent(() => import('./appsegmentnietvalide.vue')),
  kentekennieuw: defineAsyncComponent(() => import('./kentekennieuw.vue')),
  kentekenonbekend: defineAsyncComponent(() => import('./kentekenonbekend.vue')),
  kmteveel: defineAsyncComponent(() => import('./kmteveel.vue')),
  teoud: defineAsyncComponent(() => import('./teoud.vue')),
  pechonderweg: defineAsyncComponent(() => import('./pechonderweg.vue')),
  maakkeuze: defineAsyncComponent(() => import('./maakkeuze.vue')),
  errorindicatievofferte: defineAsyncComponent(() => import('./errorindicatievofferte.vue')),
  voerpostcodein: defineAsyncComponent(() => import('./voerpostcodein.vue')),
  nieuweklant: defineAsyncComponent(() => import('./nieuweklant.vue'))
}
