import { defineStore } from 'pinia'

export const useMessagesStore = defineStore('messages', {
  state: () => {
    return {
      what: null,
      showMessage: false
    }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    show(what) {
      this.showMessage = true
      this.what = what
    },
    hide() {
      this.showMessage = false
      this.what = null
    }
  },

})
