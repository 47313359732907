export default [
  /*
  * Home
  * */
  { path: '/', name: 'home', component: () => import('../views/0-home/home.vue') },
  { path: '/naw', name: 'naw', component: () => import('../views/2-naw/naw.vue') },
  { path: '/keuze', name: 'keuze', component: () => import('../views/3-keuze/keuze.vue') },
  { path: '/autobedrijf', name: 'autobedrijf', component: () => import('../views/4-autobedrijf/autobedrijf.vue') },
  { path: '/nawderest', name: 'nawderest', component: () => import('../views/5-naw-de-rest/NawDeRest.vue') },
  { path: '/conclusie', name: 'conclusie', component: () => import('../views/6-conclusie/conclusie.vue') }
]
