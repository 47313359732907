<template>

  <RouterView />

  <OverlayAdvanced v-if="messagesStore.showMessage" @close="messagesStore.hide()">
    <component :is="messageToShow"></component>
  </OverlayAdvanced>

</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { RouterView } from 'vue-router'
import { useMessagesStore } from './stores/MessagesStore'
import messages from './messages'

const messagesStore = useMessagesStore()
const messageToShow = computed(() => {
  return messages[messagesStore.what]
})

const OverlayAdvanced = defineAsyncComponent(() => import('./modal/OverlayAdvanced.vue'))
</script>


<style scoped>
</style>
